<template>
    <div
        class="VSearch"
        :class="{
            'VSearch_invalid': isInvalid,
        }"
    >
        <input
            v-model="localValue"
            ref="VSearchInput"
            class="VSearch__input"
            :placeholder="placeholder"
            @focusin="changeDropdownVisibility(true)"
            @keyup.enter="enterHandler"
        >
        <div class="VSearch__icon VSearch__icon_search"></div>
        <div
            v-show="localValue.length"
            class="VSearch__icon VSearch__icon_close"
            @click="removeValue"
        ></div>
        <div
            v-show="isShowDropdownMenu && filteredOptions.length"
            class="VSearch__dropdown-menu dropdown-menu"
        >
            <div
                v-for="(option, optionIndex) in filteredOptions"
                :key="optionIndex"
                class="dropdown-menu__option"
                @click="selectOption(option)"
            >
                {{ option[optionLabelName] }}
            </div>
        </div>
    </div>
</template>

<script>
    import clickOutside from '../mixins/clickOutside';
    
    export default {
        name: 'VSearch',
        mixins: [
            clickOutside,
        ],
        props: {
            options: {
                type: Array,
                default: () => ([]),
            },
            optionLabelName: {
                type: String,
                default: 'name',
            },
            optionValueName: {
                type: String,
                default: 'id',
            },
            placeholder: {
                type: String,
                default: '',
            },
            value: {
                type: [Number, String],
                default: 0,
            },
            isInvalid: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                isShowDropdownMenu: false,
                localValue: '',
                lastValidValue: '',
            };
        },
        computed: {
            filteredOptions() {
                return this.options.filter((option) => option[this.optionLabelName].toLowerCase().match(this.localValue.toLowerCase()));
            },
        },
        methods: {
            changeDropdownVisibility(isFocusedIn = false) {
                this.isShowDropdownMenu = isFocusedIn;
                if (this.isShowDropdownMenu) {
                    this.initClickOutsideListener({
                        key: 'VSearchInput',
                        el: this.$refs.VSearchInput,
                        callback: () => {
                            this.isShowDropdownMenu = false;
                        },
                    });
                    this.initClickOutsideListener({
                        key: 'VSearchCheckValue',
                        el: this.$el,
                        callback: () => {
                            this.checkValue();
                        },
                    });
                } else {
                    this.destroyEventClickOutsideListener('VSearchInput');
                    this.destroyEventClickOutsideListener('VSearchCheckValue');
                }
            },
            selectOption(option) {
                this.localValue = option[this.optionLabelName];
                this.lastValidValue = option[this.optionLabelName];
                this.$emit('input', option[this.optionValueName]);
            },
            checkValue() {
                this.changeDropdownVisibility();
                const targetOption = this.options.find((option) => option[this.optionLabelName].toLowerCase().match(this.localValue.toLowerCase().trim()));
                const isValidValue = targetOption ? targetOption[this.optionLabelName] : false;
                if (!isValidValue && !this.lastValidValue.length) {
                    this.removeValue();
                } else if (!isValidValue && this.lastValidValue.length) {
                    this.setLastValidValue(this.lastValidValue);
                } else if (isValidValue && this.localValue.trim().length) {
                    this.selectOption(targetOption);
                } else if (!this.localValue.trim().length) {
                    this.removeValue();
                }
            },
            setLastValidValue(lastValidValue) {
                const targetOption = this.options.find((option) => option[this.optionLabelName] === lastValidValue);
                this.selectOption(targetOption);
            },
            removeValue() {
                this.localValue = '';
                this.lastValidValue = '';
                let value;
                switch (typeof this.value) {
                    case 'string': {
                        value = '';
                        break;
                    }
                    default: {
                        value = 0;
                        break;
                    }
                }
                this.destroyEventClickOutsideListener('VSearchCheckValue');
                this.$emit('input', value);
            },
            enterHandler() {
                this.$refs.VSearchInput.blur();
                this.checkValue();
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .VSearch {
        width: 100%;
        position: relative;
        
        &__input {
            font-family: 'Nunito', sans-serif;
            display: flex;
            align-items: center;
            width: calc(100% - 98px);
            height: 54px;
            border-radius: 4px;
            padding: 0 48px;
            border: 1px solid $greyLite;
            outline: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $greyDark;
            &::placeholder {
                color: $greyMid;
            }
        }
        .dropdown-menu {
            position: absolute;
            top: 58px;
            width: calc(100% - 2px);
            background-color: $white;
            border-radius: 4px;
            border: 1px solid $greyLite;
            max-height: 242px;
            z-index: 2;
            overflow-y: auto;
            &__option {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
                display: flex;
                height: 40px;
                align-items: center;
                padding-left: 16px;
                &:hover {
                    background-color: $greyLiter;
                }
            }
        }
        &__icon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 16px;
            background-repeat: no-repeat;
            background-position: center;
            &_search {
                left: 16px;
                background-image: url("../assets/search.svg");
            }
            &_close {
                cursor: pointer;
                right: 16px;
                background-image: url("../assets/close_vsearch.svg");
            }
        }
        &_invalid {
            .VSearch__input {
                border: 1px solid $red;
            }
        }
    }
</style>