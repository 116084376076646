<template>
    <div class="header">
        <span class="header__title">
            {{ title }}
        </span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TheHeader',
        props: {
            title: {
                type: String,
                required: true,
                default: '',
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    .header {
        padding: 0 40px;
        height: 90px;
        width: 100%;
        background-color: $beige;
        position: sticky;
        top: 0;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title{
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
        }
        &__button-add {
            padding: 0 18px;
            height: 32px;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $greenLite;
            color: $green;
            cursor: pointer;
            border-radius: 4px;
            transition: all .3s;
            &:hover {
                box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
            }
        }
    }
</style>