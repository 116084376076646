<template>
    <div class="admins">
        <TheNavbar
            class="admins__navbar"
            :list="navbarList"
        />
        <TheHeader
            class="admins__header"
            title="Админы"
        >
            <div
                class="header__button-add"
                @click="showPopupAdmin"
            >
                + Добавить
            </div>
        </TheHeader>
        <div class="admins__content content">
            <div
                ref="userList"
                class="content__body"
                :style="{transform: `translate(calc((-100% - 40px) * ${activePageIndex}))`}"
            >
                <AdminRow
                    v-for="(admin, adminIndex) in admins"
                    :key="admin.id"
                    :admin="admin"
                    class="admins__row"
                >
                    {{ ++adminIndex }}
                </AdminRow>
            </div>
            <div class="content__pagination">
                <VPagination
                    v-if="pagesLength > 1"
                    :pages-length="pagesLength"
                    :active-page-index="activePageIndex"
                    @changePage="changePage"
                />
            </div>
        </div>
        <PopupAdmin/>
    </div>
</template>

<script>
    import AdminRow from '../components/Admins/AdminRow';
    import TheHeader from '../components/TheHeader';
    import TheNavbar from '../components/TheNavbar';
    import PopupAdmin from '../components/Admins/PopupAdmin';
    import VPagination from '../components/VPagination';
    import {
        getUsers,
        getUserRoles,
        updateUserRole,
    } from '../api/apiUser';
    import {eventBus} from '../main';
    
    export default {
        name: 'Admins',
        components: {
            PopupAdmin,
            TheNavbar,
            TheHeader,
            AdminRow,
            VPagination,
        },
        data() {
            return {
                activePageIndex: 0,
                listHeight: window.innerHeight,
                allUsers: [],
                userRoles: [],
            };
        },
        computed: {
            admins() {
                return this.allUsers.filter((user) => user.role === 1);
            },
            users() {
                return this.allUsers.filter((user) => user.role === 2);
            },
            pagesLength() {
                const rowHeight = 64;
                const rowMarginBottom = 10;
                const itemsPerPage = Math.floor(this.listHeight / (rowHeight + rowMarginBottom));
                return Math.ceil(this.admins.length / itemsPerPage);
            },
            navbarList() {
                return [{
                    name: 'Админы',
                    isAlwaysActive: true,
                }];
            },
        },
        mounted() {
            this.getData();
            this.setListHeight();
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },
        methods: {
            addEventListeners() {
                eventBus.$on('addAdmin', this.changeUserRole);
                eventBus.$on('deleteAdmin', this.changeUserRole);
            },
            removeEventListeners() {
                eventBus.$off('addAdmin', this.changeUserRole);
                eventBus.$off('deleteAdmin', this.changeUserRole);
            },
            getData() {
                this.getUsers();
                this.getUserRoles();
            },
            showPopupAdmin() {
                const data = {
                    mode: 'add',
                    users: this.users,
                    userId: 0,
                };
                eventBus.$emit('initPopupAdmin', data);
            },
            getUsers() {
                return getUsers().then((response) => {
                    this.allUsers = response.data;
                });
            },
            getUserRoles() {
                getUserRoles().then((response) => {
                    this.userRoles = response.data;
                    Object.freeze(this.userRoles);
                });
            },
            setListHeight() {
                const list = this.$refs.userList;
                this.listHeight = list.clientHeight;
            },
            changePage(index) {
                this.activePageIndex = index;
            },
            changeUserRole(data) {
                const targetUser = this.allUsers.find((user) => user.id === data.userId);
                const sendObj = JSON.parse(JSON.stringify(targetUser));
                sendObj.role = data.roleId;
                return updateUserRole(sendObj).then(() => {
                    targetUser.role = data.roleId;
                    const contentText = data.roleId === 1 ? 'Назначение успешно отправлено' : 'Назначение успешно удалено';
                    const notification = {
                        contentText,
                    };
                    eventBus.$emit('initNotification', notification);
                    if (this.activePageIndex === this.pagesLength) {
                        this.changePage(this.pagesLength - 1);
                    }
                });
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors.scss";
    .admins {
        height: 100%;
        overflow-x: hidden;
        scroll-behavior: smooth;
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-template-rows: 90px 1fr;
        background-color: $beige;
    
        &__navbar {
            position: fixed;
            grid-row-end: 2;
            z-index: 3;
        }
    
        &__header {
            grid-column-start: 2;
            width: calc(100% - 80px);
            z-index: 10;
        }
        
        &__row {
            margin-bottom: 10px;
            margin-right: 40px;
        }
        
        .content {
            height: calc(100vh - 90px);
            grid-column-start: 2;
            width: calc(100% - 80px);
            padding: 0 0 0 40px;
            
            &__body {
                transition: .5s;
                height: calc(100% - 58px);
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
        }
        
        &__pagination {
            align-items: center;
        }
    }
</style>