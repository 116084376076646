<template>
    <div
        v-if="isShowedPopup"
        class="popup"
        :class="{
            'popup_small': isDeleteMode,
        }"
    >
        <div class="popup__background"></div>
        <div class="popup__wrapper wrapper">
            <div
                class="button-close"
                @click="closePopup"
            ></div>
            <div class="wrapper__header">
                {{ modeState.title }}
            </div>
            <div v-if="isAddMode"
                 class="wrapper__body"
            >
                <div
                    class="wrapper__body__input input"
                >
                    <div class="input__label">
                        Пользователь
                    </div>
                    <VSearch
                        v-model="localItem.id"
                        :options="users"
                        option-label-name="fullName"
                        :is-invalid="!validation && isValidationInitialized"
                        placeholder="Выберите пользователя"
                    />
                </div>
            </div>
            <div class="wrapper__footer">
                <div
                    class="wrapper__footer__button wrapper__footer__button_cancel"
                    @click="closePopup"
                >
                    {{ modeState.cancelButtonText }}
                </div>
                <div
                    class="wrapper__footer__button wrapper__footer__button_apply"
                    @click="tryToApply"
                >
                    {{ modeState.applyButtonText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../../main';
    import VSearch from '../VSearch';
    
    export default {
        name: 'PopupAdmin',
        components: {
            VSearch,
        },
        data() {
            return {
                localItem: {
                    id: 0,
                },
                users: [],
                mode: null,
                isShowedPopup: false,
                isValidationInitialized: false,
            };
        },
        computed: {
            isAddMode() {
                return this.mode === 'add';
            },
            isDeleteMode() {
                return this.mode === 'delete';
            },
            validation() {
                return !!this.localItem.id || this.isDeleteMode;
            },
            modeState() {
                const data = {
                        add: {
                            title: 'Назначить админом',
                            applyButtonText: 'Сохранить',
                            cancelButtonText: 'Отмена',
                            methodName: 'addAdmin',
                            applyRoleId: 1,
                        },
                        delete: {
                            title: 'Вы уверены, что хотите удалить данного пользователя из списка админов?',
                            applyButtonText: 'Да, продолжить',
                            cancelButtonText: 'Нет, отменить',
                            methodName: 'deleteAdmin',
                            applyRoleId: 2,
                        },
                    };
                return data[this.mode];
            },
        },
        mounted() {
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },
        methods: {
            initPopup(data) {
                this.mode = data.mode;
                this.users = data.users;
                this.localItem.id = data.userId
                this.isShowedPopup = true;
            },
            addEventListeners() {
                eventBus.$on('initPopupAdmin', this.initPopup);
            },
            removeEventListeners() {
                eventBus.$off('initPopupAdmin', this.initPopup);
            },
            closePopup() {
                this.localItem.id = 0;
                this.isValidationInitialized = false;
                this.isShowedPopup = false;
            },
            tryToApply() {
                this.isValidationInitialized = true;
                const isValid = this.validation;
                if (isValid) {
                    this.apply();
                    this.closePopup();
                }
            },
            apply() {
                const data = {
                    userId: this.localItem.id,
                    roleId: this.modeState.applyRoleId,
                }
                eventBus.$emit(this.modeState.methodName, data);
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .popup {
        position: fixed;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
        pointer-events: all;
        display: flex;
        justify-content: center;
        
        &__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $greyDark;
            opacity: .3;
        }
        
        .wrapper {
            position: relative;
            margin-top: 20px;
            z-index: 10;
            display: flex;
            flex-direction: column;
            width: 955px;
            background-color: $white;
            height: fit-content;
            border-radius: 10px;
            padding: 40px 40px 60px 40px;
            box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    
            .button-close {
                cursor: pointer;
                position: absolute;
                top: 23px;
                right: 27px;
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("../../assets/close.svg");
                transition: all .3s;
        
                &:hover {
                    transform: rotate(0.5turn);
                }
            }
            
            &__header {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 26px;
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                color: $greyDark;
                margin-bottom: 42px;
            }
            
            &__body {
                margin-bottom: 32px;
                width: 100%;
                .input {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    
                    &__label {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: $greyMid;
                        text-transform: uppercase;
                    }
                    
                    input {
                        width: calc(100% - 20px);
                        height: 52px;
                        border-radius: 4px;
                        padding-left: 16px;
                        border: 1px solid $greyLite;
                        outline: none;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $greyDark;
                        font-family: 'Nunito', sans-serif;
                        &::placeholder {
                            font-family: 'Nunito', sans-serif;
                            color: $greyMid;
                        }
                    }
                    
                    &_not-valid {
                        input {
                            border: 1px solid $red;
                        }
                    }
                }
            }
            
            &__footer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                
                &__button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 56px;
                    padding: 0 24px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all .3s;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    
                    &:hover {
                        box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
                    }
                    
                    &_cancel {
                        height: 52px;
                        color: black;
                        border: 2px solid black;
                    }
                    
                    &_apply {
                        color: $white;
                        margin-left: 20px;
                        background-color: $green;
                    }
                }
            }
        }
        &_small {
            .wrapper {
                width: 537px;
                &__body {
                    margin-bottom: 0;
                }
                &__footer {
                    justify-content: center;
                }
            }
        }
    }
</style>