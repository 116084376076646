<template>
    <div class="row">
        <div class="row__column row__column_number">
            <slot></slot>
        </div>
        <div class="row__column row__column_name">
            {{ admin.fullName }}
        </div>
        <div class="row__column row__column_button">
            <div 
                class="row__column__button"
                @click="deleteAdmin"
            ></div>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../../main';
    
    export default {
        name: 'AdminRow',
        props: {
            admin: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        methods: {
            deleteAdmin() {
                const data = {
                    mode: 'delete',
                    users: [],
                    userId: this.admin.id,
                }
                eventBus.$emit('initPopupAdmin', data);
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
.row {
    width: 100%;
    height: 32px;
    padding: 16px 0;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 51px 1fr 72px;
    grid-column-gap: 17px;
    background-color: $white;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    &__column {
        display: flex;
        justify-content: center;
        align-items: center;
        &_name {
            justify-content: flex-start;
        }
        &_number {
            border-right: 1px solid $greyLite;
            color: $greyLite;
        }
        &__button {
            cursor: pointer;
            width: 32px;
            height: 32px;
            transition: all .3s;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../../assets/delete-button_dark.svg");
            &:hover {
                box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
            }
        }
    }
}
</style>