<template>
    <div class="navbar">
        <div class="navbar__logo"></div>
        <div class="navbar__list">
            <div
                v-for="(item, itemIndex) in list"
                :key="itemIndex"
                class="navbar__list__link"
                :class="{'navbar__list__link_active': item.isAlwaysActive}"
                @click.self="itemClickHandler(item)"
            >
                {{ item.name }}
                <div
                    v-if="item.isDeletable"
                    class="navbar__list__link__button-delete"
                    @click="deleteClickHandler(item)"
                ></div>
            </div>
        </div>
        <slot></slot>
        <div
            class="navbar__button navbar__button_logout"
            @click="logout($router)"
        >
            Выйти
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../main';
    import {
        mapActions,
    } from 'vuex';
    
    export default {
        name: 'TheNavbar',
        props: {
            list: {
                type: Array,
                required: true,
                default: () => ([]),
            },
        },
        methods: {
            ...mapActions('auth', {
                logout: 'logout',
            }),
            scrollToCategory(category) {
                eventBus.$emit('scrollToCategory', category);
            },
            deleteClickHandler(item) {
                this.$emit('deleteClickHandler', item);
            },
            itemClickHandler(item) {
                this.$emit('itemClickHandler', item);
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .navbar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        width: 300px;
        height: calc(100vh - 15px);
        padding-bottom: 15px;
        
        &__logo {
            height: 90px;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../assets/logo_dark.svg");
        }
        
        &__list {
            height: calc(100% - 135px);
            width: 100%;
            overflow-y: auto;
            margin-bottom: 20px;
            
            &::-webkit-scrollbar {
                width: 5px;
                height: 8px;
                background-color: $greyLite;
                &-thumb {
                    background-color: $green;
                    border-radius: 5px;
                }
            }
            
            &__link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(100% - 50px);
                height: 56px;
                color: $greyDark;
                border-left: 3px solid $white;
                cursor: pointer;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                padding-left: 22px;
                padding-right: 25px;
                transition: all .3s;
                word-break: break-word;
                
                
                &__button-delete {
                    opacity: 0;
                    width: 32px;
                    height: 32px;
                    transition: all .3s;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("../assets/delete-button.svg");
                }
                
                &_active {
                    background-color: $greenLite;
                    border-left: 3px solid $green;
                }
                &:hover {
                    background-color: $greenLite;
                    border-left: 3px solid $green;
                    
                    .navbar__list__link__button-delete {
                        display: block;
                        opacity: .5;
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        
        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 248px;
            height: 40px;
            cursor: pointer;
            border-radius: 4px;
            font-weight: 700;
            transition: all .3s;
            
            &:hover {
                box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
            }
            
            &_add {
                background-color: $green;
                color: $white;
                margin-bottom: 20px;
            }
            &_logout {
                background-color: $greenLite;
                color: $green;
            }
        }
    }
</style>