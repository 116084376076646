<template>
    <div class="pagination">
        <div class="pagination__container">
            <div
                class="pagination__button pagination__button_left"
                @click="changePage(activePageIndex - 1)"
            ></div>
            <div class="pagination__body">
                <template v-if="pagesLength <= 7">
                    <div
                        v-for="(page, pageIndex) in pagesLength"
                        :key="pageIndex"
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pageIndex === activePageIndex}"
                        @click="changePage(pageIndex)"
                    >
                        <span>
                            {{ page }}
                        </span>
                    </div>
                </template>
                <template v-if="pagesLength > 7 && activePageIndex <= 4">
                    <div
                        v-for="(page, pageIndex) in 5"
                        :key="pageIndex"
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pageIndex === activePageIndex}"
                        @click="changePage(pageIndex)"
                    >
                        <span>
                            {{ page }}
                        </span>
                    </div>
                    <div class="pagination__body__button pagination__body__button_not-active">
                        <span>
                            ...
                        </span>
                    </div>
                    <div
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pagesLength === activePageIndex}"
                        @click="changePage(pagesLength - 1)"
                    >
                        <span>
                            {{ pagesLength }}
                        </span>
                    </div>
                </template>
                <template v-if="pagesLength > 7 && activePageIndex >= pagesLength - 5">
                    <div
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': 0 === activePageIndex}"
                        @click="changePage(0)"
                    >
                        <span>
                            1
                        </span>
                    </div>
                    <div class="pagination__body__button pagination__body__button_not-active">
                        <span>
                            ...
                        </span>
                    </div>
                    <div
                        v-for="(page, pageIndex) in pagesLength"
                        v-show="pageIndex >= pagesLength - 5"
                        :key="pageIndex"
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pageIndex === activePageIndex}"
                        @click="changePage(pageIndex)"
                    >
                        <span>
                            {{ page }}
                        </span>
                    </div>
                </template>
                <template v-if="pagesLength > 7 && activePageIndex > 4 && activePageIndex < pagesLength - 5">
                    <div
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': 0 === activePageIndex}"
                        @click="changePage(0)"
                    >
                        <span>
                            1
                        </span>
                    </div>
                    <div class="pagination__body__button  pagination__body__button_not-active">
                        <span>
                            ...
                        </span>
                    </div>
                    <div
                        v-for="(page, pageIndex) in pagesLength"
                        v-show="pageIndex === activePageIndex || pageIndex === activePageIndex + 1 || pageIndex === activePageIndex - 1"
                        :key="pageIndex"
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pageIndex === activePageIndex}"
                        @click="changePage(pageIndex)"
                    >
                        <span>
                            {{ page }}
                        </span>
                    </div>
                    <div class="pagination__body__button  pagination__body__button_not-active">
                        <span>
                            ...
                        </span>
                    </div>
                    <div
                        class="pagination__body__button"
                        :class="{'pagination__body__button_active': pagesLength === activePageIndex}"
                        @click="changePage(pagesLength - 1)"
                    >
                        <span>
                            {{ pagesLength }}
                        </span>
                    </div>
                </template>
            </div>
            <div
                class="pagination__button pagination__button_right"
                @click="changePage(activePageIndex + 1)"
            ></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            pagesLength: {
                type: Number,
                required: true,
                default: 0,
            },
            activePageIndex: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        methods: {
            changePage(index) {
                const min = 0;
                const max = this.pagesLength - 1;
                if (index >= min && index <= max) {
                    this.$emit('changePage', index);
                }
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
        max-width: 344px;
        display: grid;
        grid-template-columns: 28px 1fr 28px;
        align-items: center;
    }
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 28px;
        height: 28px;
        transition: all .3s;
        background-repeat: no-repeat;
        background-position: center;
        &_left {
            background-image: url("../assets/chevron-left.svg");
        }
        &_right {
            background-image: url("../assets/chevron-right.svg");
        }
        
    }
    &__body {
        display: flex;
        align-items: center;
        max-width: 224px;
        overflow: hidden;
        padding: 0 10px;
        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 13px 0;
            span {
                width: 32px;
                min-width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                transition: .5s;
            }
            &:not(&_not-active):hover {
                span {
                    cursor: pointer;
                    box-shadow: 0 4px 15px rgba(50, 63, 86, 0.11);
                }
            }
            &_active {
                span {
                    color: $green;
                    background-color: $white;
                    box-shadow: 0 4px 21px rgba(50, 63, 86, 0.11);
                }
                
            }
        }
    }
}
</style>